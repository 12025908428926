import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo";
import { Row, Col } from 'reactstrap'
import MyForm from '../components/forms/formspree-form'


const Contact = () => {
  return (
    <Layout navlocation="/contact/">
    <SEO
          title={`Contact – Affordable Housing Development Corporation`}
          description={`We are here to help you build affordable housing solutions. Please contact us to discuss a potential partnership or a project you may be interested in.`}
        />
      <Row className="d-flex home__card_contact">
        <Col md={{ size:12 }} className="align-self-center text-center py-2 py-md-4">
          <h1 className="">We Are Here to Help You Build <span className="">Affordable Housing Solutions. </span></h1>
        </Col>
      </Row>
      
      <Row className="bg-dark my-3">
      <Col md={{ offset: 2, size: 8 }} className="pb-3 text-white contact__form">
      <p className="pt-3 pb-2"><span className="text-primary">We strive to develop additional affordable housing possibilities.</span> Please contact us to discuss a potential partnership or a project you may be interested in.
      </p>
      
      <MyForm/>
    
      </Col>
      </Row>
    </Layout>
  )
}

export default Contact